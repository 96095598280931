import axios from 'axios'

// const errorResponse = (error) => {
//       if (error.response) {
//             const { status, data } = error.response
//             if (status === 403) {
//                   setTimeout(() => {
//                         window.location.href = "/"
//                         localStorage.clear()
//                   }, 5000);
//             }
//             return data
//       } else {
//             return { status: 0, message: COMMON_ERROR }
//       }
// }
const COMMON_ERROR = "Error, Please try again.!"

let setAxiosUrl = axios.create({
    // baseURL: "http://192.168.40.159:8000/",
    baseURL: "https://eidodischarge.com/",
    // baseURL: `${window.location.origin}/`,
});
setAxiosUrl.interceptors.request.use((req) => {
    if (localStorage.getItem('token')) {
        req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    return req
})
export default setAxiosUrl;

const errorResponse = (error) => {
    //    return error.response
    //         ? console.error("API", error.response.data.message)
    //         : console.error("API", error.message);

    if (error?.response) {
        const { status, data } = error?.response
        console.log(status, error?.message);
        return { status: 0, message: error?.message }
    }
    else {
        return { status: 0, message: COMMON_ERROR }
    }
};
export const adminLogin = async (email, password) => {

    console.log(email,password);
    let trim_email = email.trim()
    console.log(trim_email);
    try {
        const res = await setAxiosUrl.post(`admin/sign-in`, {
            email:trim_email,
            password,
        });
        return res.data;
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const getUsersData = async (requestData) => {
    const { perPage, page, searchData } = requestData;

    try {
        const res = await setAxiosUrl.get(`admin/get-all-users?perPage=${perPage}&page=${page}&name=${searchData}`);
        // console.log(res.data);
        return res.data;
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const deleteUserData = async (id) => {
    console.log(id, "id");
    try {
        const res = await setAxiosUrl.delete(`admin/delete-user`, { data: { id } });
        //    console.log(res);
        return res.data
    } catch (error) {
        console.log(error);
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const createUserData = async (data) => {
    // console.log(data);

    const { first_name, last_name, email, password, confirmed_password, hospital } = data
    const getLowerCaseEmail = email.toLowerCase()
    // console.log(typeof getLowerCaseEmail, typeof email);

    const name = `${first_name} ${last_name}`
    try {
        const res = await setAxiosUrl.post(`admin/create-user`, { name, email: getLowerCaseEmail, password, hospital });
        console.log(res);
        return res.data
    } catch (error) {
        console.log(error);
        errorResponse(error);
        // return error.response ? error.response.data : error.message;
    }
};

export const updateUserData = async (data) => {
    console.log(data);
    const { getList, getId, getFirstNames, getLastNames } = data
    const { name, email } = getList

    const getFullName = `${getFirstNames} ${getLastNames}`
    const getLowerCaseEmail = email.toLowerCase()
    console.log({ 'name': getFullName, 'email': email, 'id': getId });
    try {
        const res = await setAxiosUrl.patch(`admin/update-user`, { 'name': getFullName, 'email': getLowerCaseEmail, 'id': getId, 'hospital': getList.hospital });
        console.log(res);
        return res.data
    } catch (error) {
        console.log(error);
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const categoryData = async (data) => {
    console.log(data);
    const { disease_name } = data

    const str  = disease_name.replace(/[^a-zA-Z ]/g, "");
    console.log(str);
    try {
        const res = await setAxiosUrl.post(`admin/create-document-category`, { 'title': str });
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const getCategoryData = async (requestData) => {
    const { perPage, page, searchData } = requestData
   
    // console.log(searchData, perPage);
    try {
        const res = await setAxiosUrl.get(`admin/get-document-category?perPage=${perPage}&page=${page}&title=${searchData}`);
        // console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const deleteCategoryData = async (id) => {
    try {
        const res = await setAxiosUrl.delete(`admin/delete-document-category`, { data: { id } });
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const updateCategoryData = async (data) => {
    console.log(data);
    const { getId, getList } = data
    const str  = getList.title.replace(/[^a-zA-Z ]/g, "");
    console.log(str);
    const setData = {
        'id': getId,
        'title': str
    }
    console.log(setData);
    try {
        const res = await setAxiosUrl.patch(`admin/update-document-category`, setData);
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const getProcedureData = async (requestData) => {
    // console.log(requestData);
    const { perPage, page, searchData } = requestData;
    // const str  = searchData.replace(/[^a-zA-Z0-9]/g, '');
    // console.log(str);
    try {
        const res = await setAxiosUrl.get(`admin/get-procedure?perPage=${perPage}&page=${page}&code=${searchData}`);
        // const res = await setAxiosUrl.get(`admin/get-procedure?code=${searchData}`);
        // console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const createProcedureData = async (formData) => {
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    console.log(formData);
    try {
        const res = await setAxiosUrl.post(`admin/create-procedure`, formData, config);
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const deleteProcedureData = async (id) => {

    try {
        const res = await setAxiosUrl.delete(`admin/delete-procedure`, { data: { id } });
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const updateProcedureData = async (data) => {
    // const { getId, getList } = data
    // console.log(getList.pdf_formate);
    // const setData = {
    //     'id': getId,
    //     'title': getList.title,
    //     'code':getList.code,
    //     'file':`pdf/${getList.pdf_formate}`
    //     // 'core_disease':
    // }
    // console.log(setData);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    try {
        const res = await setAxiosUrl.patch(`admin/update-procedure`, data, config);
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};


export const getDischargeData = async (requestData) => {
    console.log(requestData);
    const { perPage, page, searchData } = requestData;
    console.log(searchData);
    try {
        // const res = await setAxiosUrl.get(`admin/get-discharge/${perPage}/${page}`);

        const res = await setAxiosUrl.get(`admin/get-discharge?perPage=${perPage}&page=${page}&name=${searchData}`);
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};


export const getPatientData = async (requestData) => {
    console.log(requestData);
    const { perPage, page, id } = requestData;
    try {
        // const res = await setAxiosUrl.get(`admin/get-discharge/${perPage}/${page}`);
        const res = await setAxiosUrl.post(`admin/get-patient-by-id?perPage=${perPage}&page=${page}`, { id });
        console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};


export const getDiseaseData = async () => {
    // console.log(searchData, perPage);
    try {
        const res = await setAxiosUrl.get(`admin/get-document-category`);
        // console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};

export const obtainProcedureData = async () => {
    // console.log(requestData);
    try {
        const res = await setAxiosUrl.get(`admin/get-procedure`);
        // const res = await setAxiosUrl.get(`admin/get-procedure?code=${searchData}`);
        // console.log(res);
        return res.data
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};


export const getNotificationDetails = async (update) => {
    // console.log(searchData, perPage);
    // console.log(update);
    try {
        const resGetNotification = await setAxiosUrl.get(`admin/get-notification`);
        // console.log(resGetNotification);
        const { data } = resGetNotification
        const filteredData = data.data.filter((value) => {
            return value.isActive === false
        })

        const ids = filteredData.map((value) => {
            return (value._id)
        })

        // console.log(resGetNotification.data);
        // console.log(ids);
        try {
            if(update===true){
                const resUpdateNotification = await setAxiosUrl.patch(`admin/update-notification`, { ids })
                // console.log(resUpdateNotification);
            }
        }
        catch (error) {
            console.log(error);
        }
        return resGetNotification.data

    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};


export const deleteNotificationById = async (id) => {
    // console.log(requestData);
    console.log(id);
    try {
        const res = await setAxiosUrl.delete(`admin/delete-notification-By-Id?id=${id}`);
        // const res = await setAxiosUrl.get(`admin/get-procedure?code=${searchData}`);
        // console.log(res);
        // return res.data  
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};


export const deleteAllNotifications = async () => {
    // console.log(requestData);
    try {
        const res = await setAxiosUrl.delete(`admin/delete-all-notification`);
        console.log(res);
       
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};
export const getExcelData = async () => {
    console.log("requestData");
    try {
        const res = await setAxiosUrl.get(`admin/getallexceldata`);
        const { data } = res
        console.log(data,"[[[[[[[[[[[[[")
        return data
       
    } catch (error) {
        errorResponse(error);
        return error.response ? error.response.data : error.message;
    }
};