import React, { useEffect, useState } from "react";
import { Button, Col, NavLink, Pagination, Row, Table } from "react-bootstrap";
import { getDischargeData ,getExcelData} from "../../API/api";
import moment from "moment";

import Layout from "../../Layout";
import { toast } from "react-toastify";

import "./discharge.css";
import Pagination_new from "../pagination/Pagination";
import LoadingSpinner from "../LoadingSpinner/LoaderSpinner";
import { useRef } from "react";
// Math.ceil(countClient.data / perPage)
const Discharge = () => {
  const route = "/";

  const [getDisChargeDataValue, setDisChargeGetDataValue] = useState([]);
  const [month, SetMonth] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [pagination, setPagination] = useState([]);
  const [current_page, setCurrent_page] = useState([]);
  const [per_page, setPer_page] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const aRef =useRef(null)
  // const baseURL="http://192.168.40.159:8000/"
  const baseURL= "https://eidodischarge.com/"
  // const baseURL= `${window.location.origin}/`

  const getExcel= async()=>{
    console.log("first")
    setLoad(true)
    // return
try {
  const res = await getExcelData();
  if (res.status==1) {
    // const hre =  window.URL.createObjectURL("https://eidodischarge.com/"+res.data)
    const hre = baseURL+res.data;
        // aRef.current.download = res.data;
        aRef.current.href = hre;
        aRef.current.click();
        aRef.current.href = '';
        setLoad(false)
  }
} catch (error) {
  setLoad(false)
}
  }

  useEffect(() => {
    getDischarge();
  }, [search]);
  const getDischarge = async (perPage = 12, page = 1) => {
    const requestData = { perPage, page, searchData: search };
    setIsLoading(true);

    const res = await getDischargeData(requestData);
    console.log(res);
    const {
      status,
      message,
      data,
      per_page,
      count,
      current_page,
      pagination_value,
    } = res;
    // const mathValue=Math.ceil(100 / perPage)
    console.log(status, pagination_value);
    if (status === 1) {
      setIsLoading(false);

      setDisChargeGetDataValue(data);
      setPagination(pagination_value);
      setCurrent_page(Number(current_page));
      setPer_page(Number(per_page));
    } else {
      toast.error(message);
    }
    return;
    // setDisChargeGetDataValue(res);
  };
  // console.log(getDisChargeData);
  return (
    <Layout>
      <div className="discharge-main py-4 px-5">
        <Row>
          <Col xl={12} lg={12}>
            <div className="user-data">
              <div className="user-data-header d-flex align-items-center justify-content-between">
                <h1 >Discharge</h1>
                <div className="element">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Enter patient name"
                />
                <a style={{display:"none"}} herf="" ref={aRef}></a>
                <button onClick={getExcel} class="button-30" role="button" disabled={load?true:false} style={load?{width:"137px",height:"48px" }:null}>{load?<div class="spinner-border text-dark" role="status">
  <span class="sr-only"></span>
</div>:<>Download
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-arrow-down ico" viewBox="0 0 16 16">
                       <path fill-rule="evenodd" d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z"/>
                       <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                       <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                   </svg></>}
                 </button>
                </div>
              </div>
              <div className="user-data-table mt-4">
                <Table responsive className="mb-0">
                  <thead>
                    <tr>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">User Name</span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Document Name</span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Patient Name</span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Email</span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Bcc</span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Time/ Date</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDisChargeDataValue.length > 0 ? (
                      getDisChargeDataValue?.map((value) => (
                        <>
                          <tr>
                            <td>
                              <span className="d-block py-3 px-5">
                                {value.user_id?.name}
                              </span>
                            </td>
                            <td>
                              <span className="d-block py-3 px-5">
                                {value.title}
                              </span>
                            </td>
                            <td>
                              <span className="d-block py-3 px-5">
                                {/* {`${value.first_name} ${value.last_name} `} */}
                                {value.name}
                              </span>
                            </td>
                            <td>
                              <span className="d-block py-3 px-5">
                                {value.email}
                              </span>
                            </td>
                            <td>
                              <span className="d-block py-3 px-5">
                                {value.bcc}
                              </span>
                            </td>
                            <td>
                              <span className="d-block py-3 px-5">
                                {/* {value.created_at} */}
                                <>
                                  {moment(value.created_at).format("hh:mm A")}
                                  {/* :
                                {moment(value.created_at).format("mm")} */}{" "}
                                  |{" "}
                                  <span>
                                    {new Date(value.created_at).getDate()}{" "}
                                  </span>
                                  &nbsp;
                                  <span>
                                    {
                                      month[
                                        new Date(value.created_at)?.getMonth()
                                      ]
                                    }{" "}
                                  </span>{" "}
                                  &nbsp;
                                  <span>
                                    {" "}
                                    {new Date(
                                      value.created_at
                                    )?.getFullYear()}{" "}
                                  </span>
                                  &nbsp;&nbsp;
                                  {/* <span>  {moment(value.created_at).format("hh")} :{moment(value.created_at).format("mm")} </span> */}
                                </>
                                {/* 12:02 PM I 25 May 2022 */}
                              </span>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        {isLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <>
                            <tr>
                              <td colSpan={4} className="pb-3">
                                <h5 className="text-center">
                                  There is no data!
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end mt-4">
                {/* <Pagination>
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item active>{4}</Pagination.Item>
                  <Pagination.Next />
                </Pagination> */}

                {
                  <Pagination_new
                    route={route}
                    pagination={pagination}
                    current_page={current_page}
                    getData={getDischarge}
                    per_page={per_page}
                  />
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Discharge;
