import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./Components/login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import User from "./Components/user/User";
import Document from "./Components/document/Document";
import Procedure from "./Components/procedure/Procedure";
import Discharge from "./Components/discharge/Discharge";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthState } from "./context/authContext";
import UserDetails from "./Components/user/UserDetails";

const App = () => {
  const { user,saveResponse } = AuthState()
  // const user = localStorage.getItem("token")
  console.log(user,saveResponse);
  // useEffect(() => {
  //   document.title = "This is a title"
  // }, [])
  return (
    <>
      <ToastContainer />

      <Routes>
        <Route path="/LoginPage" exact element={(!user ? <Login/> :<Navigate replace to="/dashboard"/>)} />
        <Route path='/' exact element={<Navigate replace to="/dashboard" />} ></Route>

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user" element={<User />} />
        <Route path="/document" element={<Document />} />
        <Route path="/procedure" element={<Procedure />} />
        <Route path="/discharge" element={<Discharge />} />
        <Route path="/user/:id" element={<UserDetails />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />

      </Routes>
      </>
  );
};

export default App;
