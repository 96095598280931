import React from "react";
import { BiHomeAlt } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import { FaRegUser, FaVirus } from "react-icons/fa";
import { MdOutlineRefresh, MdOutlineHotel } from "react-icons/md";

import "./sidebar.css";

const Sidebar = (props) => {
  return (
    <div className={`${props.toggle ? "m-0" : ""} sidebar`}>
      <div className="logo p-4">
        <img src="../images/logo.svg" alt="logo" />
      </div>
      <div className="menu pt-4">
        <NavLink to="/dashboard">
          <span><BiHomeAlt /></span>
          Dashboard
        </NavLink>
        <NavLink to="/user" className="user-icon">
          <span><FaRegUser /></span>
          Users
        </NavLink>
        <NavLink to="/document" className="user-icon">
          {/* <FaVirus /> */}
          <span><img src="../images/document.svg" alt="" /></span>
          Document Category
        </NavLink>
        <NavLink to="/procedure">
          {/* <MdOutlineRefresh /> */}
          <span><img src="../images/procedure.svg" alt="" /></span>
          Create Procedure
        </NavLink>
        <NavLink to="/discharge">
          {/* <MdOutlineHotel /> */}
          <span><img src="../images/discharge.svg" alt="" /></span>
          Discharge
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
