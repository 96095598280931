import {createContext,useContext,useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
const AuthContext=createContext()

const AuthProvider=({children})=>{
    const[user,setUser]=useState()
    const[saveResponse,setSaveResponse]=useState()

    const navigate=useNavigate()
    useEffect(()=>{
        const userInfo=localStorage.getItem('token')
        console.log(userInfo,"userinfo");
        setUser(userInfo);
        if(!userInfo) navigate('/Loginpage')
    },[navigate])
   
    return(
        <AuthContext.Provider value={{user,setUser,saveResponse,setSaveResponse}}>
            {children}
        </AuthContext.Provider>
    )
}
export const AuthState=()=>{
    return useContext(AuthContext)
}

export default AuthProvider