import React from "react";
import {
  Dropdown,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { MdOutlineNotificationsActive, MdFilterList } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Modal,
  NavLink,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import "./header.css";
import { GrFormClose } from "react-icons/gr";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  deleteAllNotifications,
  deleteNotificationById,
  getNotificationDetails,
} from "../../API/api";

const Header = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [getValue, setGetValue] = useState([]);
  const [count, setCount] = useState();
  const [update, setUpdate] = useState(false);
  // const handleClose = () => {
  //   setShow(false);
  // };
  // const handleShow = () => {
  //   setShow(true);
  // };
  // console.log(count);
  useEffect(() => {
    getDetailsOfNotification();
  }, [update,count]);

  const getDetailsOfNotification = async () => {
    const res = await getNotificationDetails(update);
    // console.log(res);

    const { data, status, count } = res;
    if (status === 1) {
      setGetValue(data);
      setCount(count);
    }
  };

  const handleDeleteById = async (e, id) => {
    e.preventDefault();
    const res = await deleteNotificationById(id);
    // const {message,status}=res

    console.log(res);
    getDetailsOfNotification();
  };

  const handleDeleteAll = async (e) => {
    e.preventDefault();
    const res = await deleteAllNotifications();
    console.log(res);
    getDetailsOfNotification();
    setShow(!show);
  };

  //   {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
  //   <Popover id="popover-basic">
  //   {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
  //   {getValue.length > 0 &&
  //     getValue?.map((value) => (
  //       <>
  //         <Popover.Body>
  //           <h5 className={value.isActive === false ? "color:blue" : ""}>
  //             {value?.title}
  //           </h5>{" "}
  //           <span onClick={(e) => handleDeleteById(e, value._id)}>X</span>
  //           <h7>{value?.message}</h7>
  //           <h7>{value?.code}</h7>
  //           <Button onClick={handleDeleteAll}>X</Button>
  //         </Popover.Body>
  //       </>
  //     ))}
  // </Popover>

  const popover = (
    <Popover id="popover-basic" className="popover-basic">
      <Popover.Body  className="popover-custom">
        <>
          {/* {console.log(getValue)} */}
          {getValue.length > 0 ? (
            getValue?.map((value) => (
              <>
                <div className="border border-top-0 border-end-0 border-start-0 py-2 position-relative">
                  <div
                    className={
                      value.isActive === false
                        ? "primary-active p-2 rounded"
                        : "p-2 rounded"
                    }
                  >
                    <Button
                      className="btn-cross shadow-none"
                      onClick={(e) => handleDeleteById(e, value._id)}
                    >
                      X
                    </Button>
                    <h5 className="mb-1">Title: {value?.title}</h5>
                    <h6 className="mb-2">Message: {value?.message}</h6>
                    <h6>Code: {value?.code}</h6>
                  </div>
                </div>
              </>
            ))
          ) : (
            <>No notifications found</>
          )}
          {getValue.length > 0 && (
            <div className="mt-4 text-end">
              <Button
                className="shadow-none"
                variant="danger"
                onClick={handleDeleteAll}
              >
                Delete All
              </Button>
            </div>
          )}
        </>
      </Popover.Body>
    </Popover>
  );
  const LogoutHandler = () => {
    localStorage.clear();
    navigate("/LoginPage");
    toast.success("Logout successfully");
  };
  // console.log(show);
  return (
    <div className="header">
      <Button className="toggle" onClick={() => props.setToggle(!props.toggle)}>
        <MdFilterList />
      </Button>
      <div className="d-flex align-items-center">
        <OverlayTrigger trigger="click" placement="left" overlay={popover}>
          <Button
            className="notification me-4"
            ref={target}
            onClick={() => {
              setUpdate(!update);
              // setShow(!show);
              // setCount(0);
            }}
          >
            <MdOutlineNotificationsActive />
            <span>{count}</span>
          </Button>
        </OverlayTrigger>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <div className="user-toggle d-flex align-items-center gap-3">
              <span>Leon Nard</span>
              <img src="../images/avatarprofile.png" alt="user" className="h-user-img" />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => LogoutHandler()}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
