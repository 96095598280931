import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  NavLink,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Layout from "../../Layout";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";
import Pagination_new from "../pagination/Pagination";
import moment from "moment";
import "./procedure.css";
import {
  createProcedureData,
  deleteProcedureData,
  getCategoryData,
  getDiseaseData,
  getProcedureData,
  obtainProcedureData,
  updateProcedureData,
} from "../../API/api";
import LoadingSpinner from "../LoadingSpinner/LoaderSpinner";

const Procedure = () => {
  const route = "/";

  const [show, setShow] = useState(false);
  const [getProcedure, setGetProcedure] = useState([]);
  const [getDisease, setGetDisease] = useState([]);
  const [showForUpdate, setShowForUpdate] = useState(false);
  const [doc_id, setDoc_id] = useState();
  const [document_Code, setDocument_Code] = useState();
  const [procedure_Name, setProcedure_Name] = useState();
  // console.log(document_Code);
  // const [handleDataProcedure, setHandleDataProcedure] = useState([]);
  // console.log(getProcedure);
  const handleClose = () => {
    setShow(false);
    setGet_Pdf("");
    setProcedure_Name("");
    setDocument_Code("");
    setDoc_id("");
  };
  const handleShow = () => {
    setShow(true);
    setGet_Pdf("");
  };
  const [getId, setGetId] = useState();
  const handleCloseForUpdate = () => {
    setShowForUpdate(false);
    setGetList({});
  };
  const handleShowForUpdate = () => {
    setGetList({});
    setShowForUpdate(true);
  };
  const [edit_Procedure, setEdit_Procedure] = useState();
  const [get_Pdf, setGet_Pdf] = useState();
  const [getDocumentId, setGetDocumentId] = useState({});
  const [pagination, setPagination] = useState([]);
  const [current_page, setCurrent_page] = useState([]);
  const [per_page, setPer_page] = useState([]);
  const [search, setSearch] = useState("");
  const [getList, setGetList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [obtainProcedure, setObtainProcedure] = useState();
  const [fileObject, setFileObject] = useState();
  useEffect(() => {
    handleProcedureData();
  }, [search]);
  // console.log(getDocumentId,"pp");
  const handleProcedureData = async (perPage = 12, page = 1) => {
    const requestData = { perPage, page, searchData: search };
    // console.log(getDocumentId, "idform");
    setIsLoading(true);
    const res = await getProcedureData(requestData);
    console.log(res);
    const resDisease = await getDiseaseData();
    const resProcedure = await obtainProcedureData();
    // console.log(resDisease);
    setGetDisease(resDisease.data);
    setObtainProcedure(resProcedure.data);
    const {
      status,
      message,
      data,
      per_page,
      count,
      current_page,
      pagination_value,
    } = res;

    if (status === 1) {
      setIsLoading(false);
      setGetProcedure(data);
      setPagination(pagination_value);
      setCurrent_page(Number(current_page));
      setPer_page(Number(per_page));
    } else {
      setIsLoading(false);
      toast.error(message);
    }
    // setIsLoading(false);
  };

  const handleUpdate = async (value) => {
    handleShowForUpdate();
    setGetId(value._id);
    console.log(value);
    // setGetDocumentId(value.core_disease._id);
    setGetDocumentId(value.disease_core[0]._id);

    setGetList(value);
  };
  console.log(getList);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    console.log(getList.pdf_formate, "yu");

    const formData = new FormData();
    formData.append("id", getId);
    // if(getList.pdf_formate){

    // }
    formData.append("file", getList?.pdf_formate);
    formData.append("code", getList.code);
    formData.append("title", getList.title);
    formData.append("core_disease", doc_id);
    if (getList.pdf_formate == "") {
      formData.append("remove_pdf", true);
    }
    const data = await updateProcedureData(formData);

    if (typeof getList?.pdf_formate == "object") {
      if (getList?.pdf_formate.type != "application/pdf") {
        toast.error("Please select valid file");
        return;
      }
    }

    const { status, message } = data;
    if (status === 1) {
      await handleProcedureData();
      toast.success(message);
    } else {
      toast.error(message);
      return;
    }
    setGetList({});
    handleCloseForUpdate();
  };
  // console.log(getList);
  const handleDelete = async (e, value) => {
    e.preventDefault();
    console.log(value);

    if (
      window.confirm(`Are you sure you want to delete this ${value.title}?`)
    ) {
      const data = await deleteProcedureData(value._id);
      // console.log(data);
      const { status, message } = data;
      if (status === 1) {
        toast.success(message);
        handleProcedureData();
      } else {
        toast.error(message);
      }
    }
  };
  // console.log(doc_id);
  const changeFileHandler = (e) => {
    e.preventDefault();
    console.log(e.target.files[0]);
    const getType = e.target.files[0]?.type;
    console.log(getType);
    // if (getType == "application/pdf") {
    setGet_Pdf(e.target.files[0]);
    // }
    // else{
    //   setGet_Pdf("")
    // }
  };
  console.log(get_Pdf);
  const handleSubmission = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", get_Pdf);
    formData.append("code", document_Code);
    formData.append("title", procedure_Name);
    formData.append("core_disease", doc_id);

    // console.log(formData);
    // formData.append('id',getId);
    console.log(get_Pdf);
    if (
      // !get_Pdf?.name?.trim() ||
      !document_Code?.trim() ||
      !procedure_Name?.trim() ||
      !doc_id?.trim()
    ) {
      toast.error("Please fill all details");
      return;
    }
    console.log(get_Pdf.type);
    if (get_Pdf) {
      if (get_Pdf.type != "application/pdf") {
        toast.error("Please select valid file");
        return;
      }
    }

    const res = await createProcedureData(formData);
    const { status, message } = res;

    if (status === 1) {
      toast.success(message);
      handleProcedureData();
    } else {
      toast.error(message);
      return;
    }
    setGet_Pdf("");
    handleClose();
  };

  const changeFileHandlerForUpdate = (e) => {
    e.preventDefault();
    // console.log(e);
    console.log(e.target.files[0]);
    setFileObject(e.target.files[0]);
    // setGetList({...getList,pdf_formate:`pdf/${e.target.files[0].name}` })
    setGetList({ ...getList, pdf_formate: e.target.files[0] });
  };

  return (
    <Layout>
      <div className="procedure-main py-4 px-5">
        <Row>
          <Col xl={12} lg={12}>
            <div className="user-data">
              <div className="user-data-header d-flex align-items-center justify-content-between">
                <h1>Procedure</h1>
                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  placeholder="Search"
                />
              </div>
              <div className="user-data-table mt-4">
                <Table responsive className="mb-0">
                  <thead>
                    <tr>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Document Name</span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">
                          Procedure Name
                        </span>
                      </th>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Code</span>
                      </th>
                      <th className="p-0">
                        <div className="create-btn d-flex justify-content-end">
                          <span className="border-0 me-5">Actions</span>
                          <Button
                            className="me-5 ms-2"
                            onClick={() => handleShow()}
                          >
                            Create
                          </Button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getProcedure.length > 0 ? (
                      getProcedure?.map((value) => (
                        <>
                          {/* {console.log(value)}    */}
                          <tr>
                            <td>
                              <span className="d-block py-3 px-5">
                                {/* {value.core_disease?.title} */}
                                {value.disease_core[0]?.title}
                              </span>
                            </td>
                            <td>
                              <span className="py-3 px-5"> {value?.title}</span>
                            </td>
                            <td>
                              <span className="d-block py-3 px-5">
                                {" "}
                                {value.code}
                              </span>
                            </td>
                            <td>
                              <div className="delete-group d-flex align-items-center justify-content-end gap-3 py-3 pe-5">
                                <Button onClick={() => handleUpdate(value)}>
                                  Edit
                                </Button>
                                <Button onClick={(e) => handleDelete(e, value)}>
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                       
                        {isLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <>
                            <tr>
                              <td colSpan={4} className="pb-3">
                                <h5 className="text-center">
                                  There is no data!
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end mt-4">
                {/* {!search && ( */}
                <Pagination_new
                  route={route}
                  pagination={pagination}
                  current_page={current_page}
                  getData={handleProcedureData}
                  per_page={per_page}
                />
                {/* )} */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modal-title text-center position-relative">
            <h2>Create Procedure</h2>
            <Button className="modal-close-btn" onClick={() => handleClose()}>
              <GrFormClose />
            </Button>
          </div>

          <div className="modal-form my-4">
            <div className="input-field">
              <label>
                Disease Name <span>*</span>
              </label>

              <select
                value={doc_id}
                onChange={(e) => setDoc_id(e.target.value)}
                name="selected"
                id=""
              >
                <option value="">
                  Start typing for a list of matching procedures
                </option>

                {getDisease?.map((list, val) => {
                  return (
                    <option
                      // disabled={list.code == "title" ? true : null}
                      value={list._id}
                      key={val}
                      // className={list.code == "title" ? "title-none" : ""}
                    >
                      {" "}
                      {list.title}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field mt-4">
              <label>
                Procedure Name <span>*</span>
              </label>
              <input
                type="text"
                name="procedure_Name"
                onChange={(e) => setProcedure_Name(e.target.value)}
              />
            </div>
            <div className="input-field mt-4">
              <label>
                Code <span>*</span>
              </label>
              <input
                type="text"
                name="document_Code"
                onChange={(e) => setDocument_Code(e.target.value)}
              />
            </div>
          </div>

          <div className="select-field d-flex align-items-center justify-content-between gap-3">
            <div className="select-file gap-3 d-flex align-items-center">
              {get_Pdf !== "" ? (
                <>
                  {console.log(get_Pdf)}
                  <h5>
                    <b>
                      {get_Pdf?.name || get_Pdf?.pdf_formate?.split("/")[1]}
                    </b>
                  </h5>
                  <Button onClick={(e) => setGet_Pdf("")}>X</Button>
                </>
              ) : (
                <>
                  {console.log(get_Pdf)}
                  <input
                    type="file"
                    placeholder="Select PDF"
                    name="get_Pdf"
                    value={get_Pdf}
                    onChange={changeFileHandler}
                  />
                </>
              )}
              {/* 
              <input
                type="file"
                placeholder="Select PDF"
                name="get_Pdf"
                onChange={changeFileHandler}
              /> */}
              {/* <div className="w-100">
                <p>Reminder Date</p>
                <p>27 may 2023</p>
              </div> */}
            </div>
            <div className="modal-btn-group d-flex align-items-center justify-content-end gap-3">
              <Button className="cancel" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button onClick={handleSubmission} className="ok">
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showForUpdate} onHide={handleCloseForUpdate} centered>
        <Modal.Body>
          <div className="modal-title text-center position-relative">
            <h2>Edit Procedure</h2>
            <Button
              className="modal-close-btn"
              onClick={() => handleCloseForUpdate()}
            >
              <GrFormClose />
            </Button>
          </div>

          <div className="modal-form my-4">
            <div className="input-field">
              <label>Disease Name</label>

              <select
                value={
                  getList?.disease_core ? getList?.disease_core[0]?._id : ""
                }
                // value={getList?.disease_core[0]?._id}

                // onChange={(e) => setDoc_id(e.target.value)}
                name="selected"
                // id=""
              >
                <option value="">
                  Start typing for a list of matching procedures
                </option>

                {getDisease?.map((list, val) => {
                  return (
                    <option value={list._id} key={val}>
                      {" "}
                      {list.title}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field mt-4">
              <label>Procedure Name</label>
              <input
                type="text"
                // name="edit_Procedure"
                required
                value={getList.title}
                onChange={(e) =>
                  setGetList({ ...getList, title: e.target.value })
                }
                // onChange={(e) => setEdit_Procedure(e.target.value)}
              />
            </div>
            <div className="input-field mt-4">
              <label>Code</label>
              <input
                type="text"
                // name="edit_Procedure"
                required
                value={getList.code}
                onChange={(e) =>
                  setGetList({ ...getList, code: e.target.value })
                }
                // onChange={(e) => setEdit_Procedure(e.target.value)}
              />
            </div>
          </div>

          <div className="select-field d-flex align-items-center justify-content-between gap-3">
            <div className="select-file gap-3 d-flex align-items-center">
              {console.log(typeof getList.pdf_formate, "OO")}

              {getList.pdf_formate && getList.pdf_formate !== "" ? (
                <>
                  <h5>
                    <b>
                      {getList?.pdf_formate?.name ||
                        getList?.pdf_formate?.split("/")[1]}
                    </b>
                  </h5>
                  {console.log(getList)}
                  <Button
                    onClick={(e) => setGetList({ ...getList, pdf_formate: "" })}
                  >
                    X
                  </Button>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    placeholder="Select PDF"
                    // name="get_Pdf"
                    value={getList.pdf_formate?.split("/")[1]}
                    onChange={changeFileHandlerForUpdate}
                  />
                </>
              )}

              <div className="w-100">
                <p>Reminder Date</p>

                <p>{moment(getList.updated_at).add(1, "years").calendar()}</p>
              </div>
            </div>
            <div className="modal-btn-group d-flex align-items-center justify-content-end gap-3">
              <Button className="cancel" onClick={() => handleCloseForUpdate()}>
                Cancel
              </Button>

              <Button
                onClick={(e) => {
                  handleUpdateSubmit(e);
                }}
                className="ok"
              >
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Procedure;

//   const handleDataOfProcedure = async () => {
//     const data = await getDiseaseData(localStorage.getItem("token"))
//     console.log(data, "collection");
//     setHandleDataProcedure(data.data)
// }
