import React, { useState } from 'react'
import Header from './Components/header/Header'
import Sidebar from './Components/sidebar/Sidebar'

const Layout = (props) => {

  const [toggle, setToggle] = useState()

  return (
    <div>


        <Sidebar toggle={toggle}/>

        <main className='dashboard-main'>

            {
                <Header search={props.search} setSearch={props.setSearch}  setToggle={setToggle} toggle={toggle}/>
            }

            {props.children}

        </main>


    </div>
  )
}

export default Layout