import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../../Layout";

import "./dashboard.css";

const Dashboard = () => {
  return (
    <Layout>
      <div className="home-main py-4 px-5">
        <Row>
          <Col lg={4} md={4} sm={6} className="mt-3">
            <div className="category-box d-flex justify-content-between">
              <div className="category-title">
                <h5>Users</h5>
                <span>350,897</span>
              </div>
              <div className="dot"></div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={6} className="mt-3">
            <div className="category-box d-flex justify-content-between">
              <div className="category-title">
                <h5>Procedure</h5>
                <span>350,897</span>
              </div>
              <div className="dot"></div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={6} className="mt-3">
            <div className="category-box d-flex justify-content-between">
              <div className="category-title">
                <h5>Discharge</h5>
                <span>350,897</span>
              </div>
              <div className="dot"></div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Dashboard;
