import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Modal,
  NavLink,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { getPatientData } from '../../API/api';
import { AuthState } from '../../context/authContext';
import Layout from "../../Layout";
import Pagination_new from '../pagination/Pagination';
import { toast } from "react-toastify";
import LoadingSpinner from '../LoadingSpinner/LoaderSpinner';


const UserDetails = () => {
  const route = "/"

  const { id } = useParams()
  const [getPatient, setGetPatient] = useState([])
  const [data, setData] = useState()
  const [pagination, setPagination] = useState([])
  const [current_page, setCurrent_page] = useState([])
  const [per_page, setPer_page] = useState([])
  const [getUser, setGetUser] = useState([])
  const [getFirstName, setGetFirstName] = useState()
  const [getLastName, setGetLastName] = useState()
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleTablePatientData()
  }, [])

  console.log(per_page, current_page);
  const handleTablePatientData = async (perPage = 12, page = 1) => {
    const requestData = { perPage, page, id: id }

    console.log(isLoading);

    console.log("hello");
    setIsLoading(true)
    const res = await getPatientData(requestData)
    const { status, message, data, per_page, count, current_page, pagination_value, current_user } = res
    console.log(current_page, per_page, pagination_value);
    if (status === 1) {
      setIsLoading(false);
      setGetPatient(data)
      setGetUser(current_user)
      const [firstName, lastName] = current_user.name.split(" ")
      setGetFirstName(firstName)
      setGetLastName(lastName)
      setPagination(pagination_value)
      setCurrent_page(current_page)
      setPer_page(per_page)
      if (data.length === 0) {
        setPagination([])
        setCurrent_page([])
        setPer_page([])
      }
      // setData(res)
    } else {
      toast.error(message)
      setIsLoading(false);
    }

  }
  return (
    <Layout>
      <div className="user-main py-4 px-5">
        <Row>
          <Col xl={11} lg={12}>

            <div className="user-info">
              <h5>User Information</h5>

              <Row className="mt-4">
                <Col lg={6} md={6} sm={12} className="mt-3">
                  <div className="input-field mb-3">
                    <label>First Name</label>
                    <input type="text"
                      value={getFirstName}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-3">
                  <div className="input-field mb-3">
                    <label>Last Name</label>
                    <input type="text"
                      value={getLastName}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-3">
                  <div className="input-field mb-3">
                    <label>Email Address</label>
                    <input type="text" value={getUser.email} />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-3">
                  <div className="input-field mb-3">
                    <label>Hospital</label>
                    <input type="text"
                      value={getUser.hospital}
                    />
                  </div>
                </Col>
              </Row>

              <div className="patient-name mt-5">
                <label>Patient Name</label>
                
                    <div className="user-data-table">
                      <Table responsive className="mb-0">
                        <thead>
                          <tr>
                            <th className="p-0">
                              <span className="d-block py-3 px-5">Name</span>
                            </th>
                            <th className="p-0">
                              <span className="d-block py-3 px-5">Email</span>
                            </th>
                            <th className="p-0">
                              <span className="d-block py-3 px-5">Bcc</span>
                            </th>
                            <th className="p-0">
                              <span className="d-block py-3 px-5">Disease</span>
                            </th>
                            <th className="p-0">
                              <span className="d-block py-3 px-5">Code</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            getPatient.length > 0 ?
                              getPatient?.map((value) => (
                                <>
                                  {/* {console.log(value)} */}
                                  <tr>
                                    <td>
                                      <span className="d-block py-3 px-5">
                                        {value?.name || `${value.first_name} ${value.last_name}`}
                                      </span>
                                    </td>
                                    <td>
                                      {value?.email}
                                    </td>
                                    <td>
                                      <span className="d-block py-3 px-5">{value?.bcc}</span>
                                    </td>
                                    <td>
                                      <span className="d-block py-3 px-5">{value.disease_id?.title}</span>
                                    </td>
                                    <td>
                                      <span className="d-block py-3 px-5">{value.disease_id?.code}</span>
                                    </td>
                                  </tr>
                                </>
                              ))
                              : <>
                                
                                {isLoading ? (
                                  <LoadingSpinner />
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={4} className="pb-3">
                                        <h5 className="text-center">
                                          There is no data!
                                        </h5>
                                      </td>
                                    </tr>
                                  </>
                                )}

                                {/* <p className='text-center'>Data Not Found</p> */}
                              </>
                          }

                        </tbody>
                      </Table>
                    </div>
                  
                 
                <div className="d-flex justify-content-end mt-4">

                  <Pagination_new
                    route={route}
                    pagination={pagination}
                    current_page={current_page}
                    getData={handleTablePatientData}
                    per_page={per_page}
                  />
                </div>

              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default UserDetails