import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  NavLink,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Layout from "../../Layout";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";

import "./document.css";
import {
  categoryData,
  deleteCategoryData,
  getCategoryData,
  updateCategoryData,
} from "../../API/api";
import Pagination_new from "../pagination/Pagination";
import LoadingSpinner from "../LoadingSpinner/LoaderSpinner";

const Document = () => {
  const route = "/";

  const [show, setShow] = useState(false);
  const [showForUpdate, setShowForUpdate] = useState(false);
  const [getDocumentsData, setGetDocumentsData] = useState([]);
  const [updateUser, setUpdateUser] = useState({
    update_first_name: "",
    update_last_name: "",
    update_email: "",
  });
  const [getId, setGetId] = useState();
  const [getList, setGetList] = useState({});
  // const [diseaseData, setDiseaseData] = useState([]);
  const [edit_disease, setEdit_Document] = useState();

  const handleClose = () => {
    setShow(false);
    setDisease_name("");
  };
  const handleShow = () => {
    setShow(true);
    setDisease_name("");
  };
  const handleCloseForUpdate = () => setShowForUpdate(false);
  const handleShowForUpdate = () => setShowForUpdate(true);
  const [disease_name, setDisease_name] = useState();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState([]);
  const [current_page, setCurrent_page] = useState([]);
  const [per_page, setPer_page] = useState([]);
  // console.log(disease_name);
  // console.log(edit_disease);
  const handleGetDiseaseData = async (perPage = 12, page = 1) => {
    const requestData = { perPage, page, searchData: search };
    setIsLoading(true);
    const res = await getCategoryData(requestData);
    // console.log(res);
    const {
      status,
      message,
      data,
      per_page,
      count,
      current_page,
      pagination_value,
    } = res;
    if (status === 1) {
      setIsLoading(false);
      setGetDocumentsData(data);
      setPagination(pagination_value);
      setCurrent_page(current_page);
      setPer_page(per_page);
    } else {
      toast.error(message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetDiseaseData();
  }, [search]);

  const handleCategoryButton = async (e) => {
    e.preventDefault();

    if (!disease_name?.trim()) {
      toast.error("Please fill  field");
      return;
    }

    const data = await categoryData({ disease_name });
    console.log(data);
    const { status, message } = data;
    if (status === 1) {
      await handleGetDiseaseData();
      toast.success(message);
    } else {
      toast.error(message);
    }
    handleClose();
  };
  const handleUpdate = async (list) => {
    console.log(list);
    handleShowForUpdate();
    setGetId(list._id);
    setGetList(list);
  };
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!getList.title?.trim()) {
      toast.error("Please fill all field");
      return;
    }

    const data = await updateCategoryData({ getId, getList });
    console.log(data);
    const { status, message } = data;
    if (status === 1) {
      toast.success(message);
      handleGetDiseaseData();
    } else {
      toast.error(message);
    }
    handleCloseForUpdate();
  };
  const handleDelete = async (e, value) => {
    e.preventDefault();
    console.log(value);
    if (
      window.confirm(`Are you sure you want to delete this ${value.title}?`)
    ) {
      const data = await deleteCategoryData(value._id);
      console.log(data);
      const { status, message } = data;
      if (status === 1) {
        toast.success(message);
        handleGetDiseaseData();
      } else {
        toast.error(message);
      }
    }
  };
  console.log(search);
  // const onChangeForUpdate = (e) => {
  //   setUpdateUser((prevState) => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value,
  //   }));
  // };
  return (
    <Layout>
      <div className="document-main py-4 px-5">
        <Row>
          <Col xl={12} lg={12}>
            <div className="user-data">
              <div className="user-data-header d-flex align-items-center justify-content-between">
                <h1>Document Category</h1>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Enter category name"
                />
              </div>
              <div className="user-data-table mt-4">
                <Table responsive className="mb-0">
                  <thead>
                    <tr>
                      <th className="p-0">
                        <span className="d-block py-3 px-5">Category Name</span>
                      </th>
                      <th className="p-0">
                        <div className="create-btn d-flex justify-content-end">
                          <span className="border-0 me-5">Actions</span>
                          <Button
                            className="me-5 ms-2"
                            onClick={() => handleShow()}
                          >
                            Create
                          </Button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {getDocumentsData.length > 0 ? (
                        getDocumentsData?.map((list) => (
                          <>
                            <tr>
                              <td>
                                <span className="d-block py-3 px-5">
                                  <></>
                                  {list?.title}
                                </span>
                              </td>
                              <td>
                                <div className="delete-group d-flex align-items-center justify-content-end gap-3 py-3 pe-5">
                                  <Button onClick={() => handleUpdate(list)}>
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={(e) => handleDelete(e, list)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <>
                          {isLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <>
                              <tr>
                                <td colSpan={4} className="pb-3">
                                  <h5 className="text-center">
                                    There is no data!
                                  </h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </>
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end mt-4">
                {/* <Pagination>
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item active>{4}</Pagination.Item>
                  <Pagination.Next />
                </Pagination> */}
                {/* { !search && */}

                <Pagination_new
                  route={route}
                  pagination={pagination}
                  current_page={current_page}
                  getData={handleGetDiseaseData}
                  per_page={per_page}
                />
                {/* // } */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modal-title text-center position-relative">
            <h2>Create Document Category</h2>
            <Button className="modal-close-btn" onClick={() => handleClose()}>
              <GrFormClose />
            </Button>
          </div>

          <div className="modal-form my-4">
            <div className="input-field">
              <label>
                Disease Name <span>*</span>
              </label>
              <input
                type="text"
                name="disease_name"
                onChange={(e) => setDisease_name(e.target.value)}
              />
            </div>
          </div>

          <div className="modal-btn-group d-flex align-items-center justify-content-end gap-3">
            <Button className="cancel" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button onClick={(e) => handleCategoryButton(e)} className="ok">
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showForUpdate} onHide={handleCloseForUpdate} centered>
        <Modal.Body>
          <div className="modal-title text-center position-relative">
            <h2>Edit Document</h2>
            <Button
              className="modal-close-btn"
              onClick={() => handleCloseForUpdate()}
            >
              <GrFormClose />
            </Button>
          </div>

          <div className="modal-form my-4">
            <div className="input-field">
              <label>Disease Name</label>
              <input
                type="text"
                name="edit_document"
                required
                value={getList.title}
                onChange={(e) =>
                  setGetList({ ...getList, title: e.target.value })
                }
              />
            </div>
          </div>

          <div className="modal-btn-group d-flex align-items-center justify-content-end gap-3">
            <Button className="cancel" onClick={() => handleCloseForUpdate()}>
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleUpdateSubmit(e);
              }}
              className="ok"
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Document;
