import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  NavLink,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Layout from "../../Layout";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";
import "./user.css";
import {
  deleteUserData,
  getUsersData,
  createUserData,
  updateUserData,
} from "../../API/api";
import validator from "validator";
import Pagination_new from "../pagination/Pagination";
import { Link, Navigate } from "react-router-dom";
import { AuthState } from "../../context/authContext";
import LoadingSpinner from "../LoadingSpinner/LoaderSpinner";

const User = (props) => {
  const route = "/";
  const { setSaveResponse } = AuthState();
  const [update, setUpdate] = useState(true);
  const [show, setShow] = useState(false);
  const [showForUpdate, setShowForUpdate] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [modalCon, setModalCon] = useState(true);
  const [getId, setGetId] = useState();
  const [pagination, setPagination] = useState([]);
  const [current_page, setCurrent_page] = useState([]);
  const [per_page, setPer_page] = useState([]);
  const [search, setSearch] = useState("");
  const [getList, setGetList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCreateUser({});
  };
  const handleShow = () => setShow(true);
  const handleCloseForUpdate = () => setShowForUpdate(false);
  const handleShowForUpdate = () => setShowForUpdate(true);
  const [getFirstNames, setGetFirstNames] = useState();
  const [getLastNames, setGetLastNames] = useState();
  const [createUser, setCreateUser] = useState({
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    password: "",
    confirmed_password: "",
    hospital: "",
  });
  // const [updateUser, setUpdateUser] = useState({
  //   update_first_name: "",
  //   update_last_name: "",
  //   update_email: "",
  // });
  const {
    name,
    email,
    password,
    confirmed_password,
    first_name,
    last_name,
    hospital,
  } = createUser;

  // const { update_first_name, update_last_name, update_email } = updateUser;
  // console.log(createUser);

  const handleGetUsersData = async (perPage = 12, page = 1) => {
    const requestData = { perPage, page, searchData: search };
    setIsLoading(true);
    const res = await getUsersData(requestData);
    const {
      status,
      message,
      data,
      per_page,
      count,
      current_page,
      pagination_value,
    } = res;
    // console.log(res);
    if (status === 1) {
      setIsLoading(false);
      setUsersData(data);
      setPagination(pagination_value);
      setCurrent_page(current_page);
      setPer_page(per_page);
      // handleGetUsersData();
    } else {
      toast.error(message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetUsersData();
  }, [search]);

  const handleDelete = async (e, value) => {
    e.preventDefault();
    console.log(value.name);
    console.log(typeof value.name);
    if (window.confirm(`Are you sure you want to delete this ${value.name}?`)) {
      const data = await deleteUserData(value._id);
      console.log(data);
      const { status, message } = data;
      if (status === 1) {
        await handleGetUsersData();
        toast.success(message);
      }
      if (status === 0) {
        toast.error(message);
      }
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    // setCreateUser({})
    if (createUser) {
      console.log(createUser);
      if (
        !first_name.trim() ||
        !last_name.trim() ||
        !email.trim() ||
        !password.trim() ||
        !confirmed_password.trim() ||
        !hospital?.trim()
      ) {
        toast.error("Please fill all fields");
        return;
      }
    }

    // let gotString = "Hello sdfsdf";
    // // console.log(gotString.trim());
    // const a = gotString.trim().split(" ")[1]?.length >= 0;
    // console.log(a);

  

    const getFirstName = first_name.indexOf(" ") >= 0;
    if (getFirstName == 1) {
      toast.error("Please enter first valid name");
      return;
    }

    const getLastName = last_name.indexOf(" ") >= 0;
    if (getLastName == 1) {
      toast.error("Please enter valid last name");
      return;
    }
    if (password.length <= 5) {
      toast.error("Password length must be greater than 6 characters");
      return;
    }
    if (first_name.length <= 2) {
      toast.error("firstname length must be greater than 2 characters");
      return;
    }

    if (last_name.length <= 2) {
      toast.error("lastname length must be greater than 2 characters");
      return;
    }

    if (hospital.length <= 2) {
      toast.error("hospital length must be greater than 2 characters");
      return;
    }

    if (!validator.isEmail(email.trim())) {
      toast.error("Please enter valid email address");
      return;
    }

    try {
      if (password !== confirmed_password) {
        toast.error("Password doesn't match");
        return;
      } else {
        const data = await createUserData({
          first_name,
          last_name,
          email,
          password,
          confirmed_password,
          hospital,
        });

        console.log(data);
        const { status, message } = data;
        console.log(status, message);

        if (status === 1) {
         await  handleGetUsersData();
          toast.success(message);
        } else {
          toast.error(message);
          return;
        }
      }
    } catch (error) {
      console.log(error);
      return;
    }

    setCreateUser({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmed_password: "",
      hospital: "",
    });
    handleClose();
  };

  const handleUpdate = async (value) => {
    handleShowForUpdate();
    setGetId(value._id);
    setGetList(value);
    const [firstName, lastName] = value?.name?.split(" ");
    // console.log(data);
    // const [firstName, lastName] = getList?.name?.split(" ")
    //   console.log(firstName,lastName);
    setGetFirstNames(firstName);
    setGetLastNames(lastName);
  };
  // console.log(getFirstNames);
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (getList) {
      if (
        !getFirstNames.trim() ||
        !getLastNames.trim() ||
        !getList.email.trim() ||
        !getList.hospital.trim()
        // !update_last_name.trim()
      ) {
        toast.error("Please fill all field");
        return;
      }
      // console.log("op");
      // const passingSpace = getFirstNames(getFirstNames.length-1)
      // console.log(passingSpace);

      // const checkSpace = getFirstNames.indexOf(" ")
      // console.log(checkSpace);
      const getFirstName = getFirstNames.indexOf(" ") >= 0;
      if (getFirstName == 1) {
        toast.error("Please enter first valid name");
        return;
      }

      const getLastName = getLastNames.indexOf(" ") >= 0;
      if (getLastName == 1) {
        toast.error("Please enter valid last name");
        return;
      }
    }

    if (!validator.isEmail(getList.email.trim())) {
      toast.error("Please enter valid email address");
      return;
    }

    console.log(getId, "qq");
    const data = await updateUserData({
      getList,
      getId,
      getFirstNames,
      getLastNames,
    });
    console.log(data, "data");

    const { status, message } = data;
    if (status === 1) {
      toast.success(message);
      handleGetUsersData();
    } else {
      toast.error("Email Already Exist");
      return;
    }
    // const getId=await handleUpdate()
    // console.log(getId);
    setGetFirstNames(" ");
    setGetLastNames(" ");
    handleCloseForUpdate();
  };

  // console.log(getList);
  const onChange = (e) => {
    setCreateUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // const openPost = (id) => {
  //   console.log(id);

  //   <Navigate replace to={`/user/${id}`}></Navigate>;
  // };
  return (
    <Layout>
      {update ? (
        <div className="user-main py-4 px-5">
          <Row>
            <Col xl={12} lg={12}>
              <div className="user-data">
                <div className="user-data-header d-flex align-items-center justify-content-between">
                  <h1>Users</h1>
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Enter name"
                  />
                </div>
                <div className="user-data-table mt-4">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th className="p-0">
                          <span className="d-block py-3 px-5">Name</span>
                        </th>
                        <th className="p-0">
                          <span className="d-block py-3 px-5">
                            Email Address
                          </span>
                        </th>
                        <th className="p-0">
                          <span className="d-block py-3 px-5">Hospital</span>
                        </th>
                        <th className="p-0">
                          <div className="create-btn d-flex justify-content-end">
                            <span className="border-0 me-5">Actions</span>
                            <Button className="me-5 ms-2" onClick={handleShow}>
                              Create
                            </Button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersData.length ? (
                        usersData?.map((value) => (
                          // console.log(value)
                          <>
                            <tr>
                              <td>
                                <span className="d-block py-3 px-5">
                                  <Link to={`/user/${value._id}`}>
                                    {value?.name}
                                  </Link>
                                </span>
                              </td>
                              <td>
                                <span className="py-3 px-5">
                                  {value?.email}
                                </span>
                              </td>
                              <td>
                                <span className="py-3 px-5">
                                  {value?.hospital}
                                </span>
                              </td>
                              <td>
                                <div className="delete-group d-flex align-items-center justify-content-end gap-3 py-3 pe-5">
                                  <Button
                                    onClick={(e) => handleDelete(e, value)}
                                  >
                                    Delete
                                  </Button>
                                  <Button onClick={() => handleUpdate(value)}>
                                    Edit
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <>
                         {isLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <>
                              <tr>
                                <td colSpan={4} className="pb-3">
                                  <h5 className="text-center">
                                    There is no data!
                                  </h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  {/* {!search &&  */}
                  <Pagination_new
                    route={route}
                    pagination={pagination}
                    current_page={current_page}
                    getData={handleGetUsersData}
                    per_page={per_page}
                  />
                  {/* } */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="user-main py-4 px-5">
          <Row>
            <Col xl={11} lg={12}>
              <div className="user-info">
                <h5>User Information</h5>
                {/* <Row className="mt-4">
                  <Col lg={6} md={6} sm={12} className="mt-3">
                    <div className="input-field">
                      <label>First Name</label>
                      <input type="text" name="update_first_name"  required onChange={onChangeForUpdate} />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-3">
                    <div className="input-field">
                      <label>Last Name</label>
                      <input type="text" name="update_last_name" required onChange={onChangeForUpdate} />
                    </div>
                  </Col>
                </Row>
                <div className="input-field mt-3">
                  <label>Email Address</label>
                  <input type="text" name="update_email" required onChange={onChangeForUpdate} />
                </div> */}
                <div className="patient-name mt-5">
                  <label>Patient Name</label>
                  <div className="user-data-table">
                    <Table responsive className="mb-0">
                      <thead>
                        <tr>
                          <th className="p-0">
                            <span className="d-block py-3 px-5">Name</span>
                          </th>
                          <th className="p-0">
                            <span className="d-block py-3 px-5">Email</span>
                          </th>
                          <th className="p-0">
                            <span className="d-block py-3 px-5">Bcc</span>
                          </th>
                          <th className="p-0">
                            <span className="d-block py-3 px-5">Disease</span>
                          </th>
                          <th className="p-0">
                            <span className="d-block py-3 px-5">Code</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="d-block py-3 px-5">
                              John Revon
                            </span>
                          </td>
                          <td>
                            <NavLink>John152@gmail.com</NavLink>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="d-block py-3 px-5">
                              John Revon
                            </span>
                          </td>
                          <td>
                            <NavLink>John152@gmail.com</NavLink>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="d-block py-3 px-5">
                              John Revon
                            </span>
                          </td>
                          <td>
                            <NavLink>John152@gmail.com</NavLink>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="d-block py-3 px-5">
                              John Revon
                            </span>
                          </td>
                          <td>
                            <NavLink>John152@gmail.com</NavLink>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="d-block py-3 px-5">
                              John Revon
                            </span>
                          </td>
                          <td>
                            <NavLink>John152@gmail.com</NavLink>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                          <td>
                            <span className="d-block py-3 px-5">XYZ</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <Pagination_new
                      route={route}
                      pagination={pagination}
                      current_page={current_page}
                      getData={handleGetUsersData}
                      per_page={per_page}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modal-title text-center position-relative">
            <h2>Create User</h2>
            <Button className="modal-close-btn" onClick={() => handleClose()}>
              <GrFormClose />
            </Button>
          </div>

          <div className="modal-form my-4">
            {/* <div className="input-field">
              <label>Name</label>
              <input type="text" name="name" required onChange={onChange} />
            </div> */}

            <div className="input-field mt-4">
              <label>
                First Name <span>*</span>
              </label>
              <input
                type="text"
                name="first_name"
                required
                onChange={onChange}
              />
            </div>

            <div className="input-field mt-4">
              <label>
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                name="last_name"
                required
                onChange={onChange}
                minLength={4}
              />
            </div>

            <div className="input-field mt-4">
              <label>
                Email <span>*</span>
              </label>
              <input type="text" name="email" required onChange={onChange} />
            </div>

            <div className="input-field mt-4">
              <label>
                Hospital <span>*</span>
              </label>
              <input
                type="text"
                name="hospital"
                required
                // onChange={onChangeForUpdate}
                // value={}
                onChange={onChange}
              />
            </div>

            <div className="input-field mt-4">
              <label>
                Password <span>*</span>
              </label>
              <input
                type="password"
                name="password"
                required
                onChange={onChange}
                maxLength={30}
                minLength={5}
              />
            </div>
            <div className="input-field mt-4">
              <label>
                Confirm Password <span>*</span>
              </label>
              <input
                type="password"
                name="confirmed_password"
                required
                onChange={onChange}
                maxLength={30}
                minLength={5}
              />
            </div>
          </div>

          <div className="modal-btn-group d-flex align-items-center justify-content-end gap-3">
            <Button className="cancel" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleCreate(e);
              }}
              className="ok"
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showForUpdate} onHide={handleCloseForUpdate} centered>
        <Modal.Body>
          <div className="modal-title text-center position-relative">
            <h2>Edit User</h2>
            <Button
              className="modal-close-btn"
              onClick={() => handleCloseForUpdate()}
            >
              <GrFormClose />
            </Button>
          </div>

          <div className="modal-form my-4">
            <div className="input-field">
              <label>First Name</label>
              <input
                type="text"
                name="update_first_name"
                required
                onChange={(e) => setGetFirstNames(e.target.value)}
                value={getFirstNames}
                // onChange={(e) =>
                //   setGetList({ ...getList, name: e.target.value })
                // }
              />
            </div>
            <div className="input-field mt-4">
              <label>Last Name</label>
              <input
                type="text"
                name="update_last_name"
                required
                onChange={(e) => setGetLastNames(e.target.value)}
                value={getLastNames}
                // onChange={(e) =>
                //   setGetList({ ...getList, name: e.target.value })
                // }
              />
            </div>
            <div className="input-field mt-4">
              <label>Email</label>
              <input
                type="email"
                name="update_email"
                required
                // onChange={onChangeForUpdate}
                value={getList.email}
                onChange={(e) =>
                  setGetList({ ...getList, email: e.target.value })
                }
              />
            </div>
            <div className="input-field mt-4">
              <label>Hospital</label>
              <input
                type="email"
                name="update_hospital"
                required
                // onChange={onChangeForUpdate}
                value={getList.hospital}
                onChange={(e) =>
                  setGetList({ ...getList, hospital: e.target.value })
                }
              />
            </div>
          </div>

          <div className="modal-btn-group d-flex align-items-center justify-content-end gap-3">
            <Button className="cancel" onClick={() => handleCloseForUpdate()}>
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleUpdateSubmit(e);
              }}
              className="ok"
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default User;

// const [firstName, lastName] = getList.name.split(" ")
// console.log(firstName,lastName);
// const getFirstName = firstName.indexOf(" ") >= 0;
// if (getFirstName == 1) {
//   toast.error("Please enter first valid name");
//   return;
// }

// const getLastName = lastName.indexOf(" ") >= 0;
// if (getLastName == 1) {
//   toast.error("Please enter valid last name");
//   return;
// }
