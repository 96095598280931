import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { adminLogin } from '../../API/api'
import { toast } from "react-toastify";
import validator from 'validator';

import './login.css'
import { AuthState } from '../../context/authContext';

const Login = () => {
    const {setSaveResponse} = AuthState()
    const navigate =useNavigate()

    const [formData,setFormData]=useState({'email':'','password':''})
    const { email, password } = formData;

    // email.trim()

    const login=async(email,password)=>{
        const loginRes = await adminLogin(email, password)
        console.log(loginRes);
        if (loginRes.status===1) {
            localStorage.setItem("token",  loginRes.token);
            localStorage.setItem("email", `${email}`);
            // setLoginInfo(loginRes)
            setSaveResponse(loginRes.email)
            toast.success(loginRes.message)
            navigate("/")
        }
        else if (!validator.isEmail(email.trim())) {
             toast.error("Please enter valid email address")
             return
           }
        else{
            toast.error("Please fill correct details")
        }
    }
    const handleSubmit=async(e)=>{ 
        e.preventDefault()
        await login(email,password)
    }
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
  return (
        <div className='login d-flex align-items-center justify-content-center'>
            <div className='login-box p-5 text-center'>
                <h1>Login</h1>
                <div className='login-form my-5'>
                    <input type="text" name='email'  placeholder='Email Address'  required onChange={onChange}/>
                    <input className='mt-4' name='password' type="password" placeholder='Password'  required onChange={onChange}/>
                    <div className='d-flex justify-content-end'>
                        {/* <Link to="/" className='forgot-pass'>Forgot Password?</Link> */}
                    </div>
                </div>
                <button onClick={handleSubmit} className='login-btn'> Login </button>

                {/* <Link to="/dashboard" className='login-btn'>Login</Link> */}
            </div>
        </div>
  )
}

export default Login